<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="!$store.state.loading"
    ></BaseLoading>
    <div v-else class="container my-4">
      <div class="row">
        <div class="col-12 col-sm-8 py-2 text-left" style="border-radius: 4px">
          <div class="card">
            <div class="card-body">
              <h1>
                Edit Order
                <router-link
                  :to="{ name: 'Order', params: { id: order.id } }"
                  class="btn bg-teal float-right"
                >
                  Go Back to Order
                </router-link>
              </h1>
              <h4>Paper Details</h4>

              <form @submit.prevent="doUpdateOrder">
                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Type of Document</label
                  >
                  <div class="col-sm-9">
                    <v-select
                      label="desc"
                      v-model="orderForm.document_type"
                      placeholder="Start typing to search"
                      @input="calculatePrice"
                      :options="document_types"
                      :reduce="(doc) => doc.id"
                    ></v-select>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Subject Area</label
                  >
                  <div class="col-sm-9">
                    <v-select
                      label="desc"
                      v-model="orderForm.subject_area"
                      placeholder="Start typing to search"
                      @input="calculatePrice"
                      :options="subject_areas"
                      :reduce="(area) => area.id"
                    ></v-select>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Academic Level</label
                  >
                  <div class="col-sm-9 text-left">
                    <div class="btn-group" v-if="academic_levels.length > 0">
                      <button
                        type="button"
                        class="btn"
                        v-for="(level, index) in academic_levels"
                        :key="index + 'al'"
                        :class="
                          orderForm.academic_level === level.id
                            ? 'btn-dark'
                            : 'btn-outline-dark'
                        "
                        @click="activateAcademicLevel(level)"
                      >
                        {{ level.desc }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Title</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="orderForm.title"
                      class="form-control"
                      id="inputEmail3"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Description</label
                  >
                  <div class="col-sm-9">
                    <textarea
                      rows="4"
                      v-model="orderForm.description"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="Description"
                    ></textarea>

                    <div class="alert alert-warning mt-2">
                      Your personal information (Phone Number, Email Address) is
                      always kept private and we won't share it–just don't
                      include it in your order details.
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >State</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="orderForm.state"
                      class="form-control"
                      id="inputEmail3"
                    />
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Nursing Speciality</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="text"
                      v-model="orderForm.speciality"
                      class="form-control"
                      id="inputEmail3"
                    />
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Order Files</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="file"
                      @change="uploadFiles"
                      class="form-control"
                      id="inputEmail3"
                      placeholder="Choose files to upload"
                      multiple="multiple"
                    />
                    <p>Select multiple files</p>
                    <div
                      class="mt-3 p-3 bg-teal-300 text-white rounded"
                      v-if="orderForm.files.length > 0"
                    >
                      Selected files:
                      <br />
                      <small v-for="(file, index) in orderForm.files" :key="index">
                        {{ file.name }}
                        <br />
                      </small>
                    </div>
                  </div>
                </div> -->

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Paper format/Style</label
                  >
                  <div class="col-sm-9">
                    <v-select
                      label="desc"
                      v-model="orderForm.style"
                      placeholder="Start typing to search"
                      :options="styles"
                      :reduce="(style) => style.id"
                    ></v-select>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Language Style</label
                  >
                  <div class="col-sm-9">
                    <div class="btn-group" v-if="language_styles.length > 0">
                      <button
                        type="button"
                        class="btn"
                        v-for="(style, index) in language_styles"
                        :key="index"
                        :class="
                          orderForm.language_style === style.id
                            ? 'btn-dark'
                            : 'btn-outline-dark'
                        "
                        @click="activateLanguageStyle(style)"
                      >
                        {{ style.desc }}
                      </button>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Deadline / Urgency</label
                  >
                  <div class="col-sm-9">
                    <v-select
                      label="desc"
                      v-model="orderForm.urgency"
                      placeholder="Start typing to search"
                      @input="calculatePrice"
                      :options="urgency"
                      :reduce="(urg) => urg.id"
                    ></v-select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <div class="form-group row">
                      <label
                        class="
                          col-form-label col-12 col-sm-6 col-md-4
                          text-right
                        "
                        >Pages</label
                      >
                      <div class="col-12 col-sm-6 col-md-8">
                        <div class="input-group">
                          <span class="input-group-prepend">
                            <button
                              class="btn btn-dark btn-icon"
                              type="button"
                              @click="updatePages(0)"
                            >
                              <i class="icon-minus3"></i>
                            </button>
                          </span>
                          <input
                            type="number"
                            @change="calculatePrice"
                            v-model="orderForm.pages"
                            name="pages"
                            min="0"
                            :max="number_of_pages.max_pages"
                            class="form-control text-center"
                            placeholder
                          />
                          <span class="input-group-append">
                            <button
                              class="btn btn-dark btn-icon"
                              type="button"
                              @click="updatePages(1)"
                            >
                              <i class="icon-plus3"></i>
                            </button>
                          </span>
                        </div>
                        <strong class="mt-1"
                          >Aprox {{ no_of_words || "275" }} words</strong
                        >
                      </div>
                    </div>
                  </div>

                  <div class="col-12 py-0 py-0"></div>

                  <div class="col-md-12 col-12">
                    <div class="form-group row">
                      <label
                        for="inputEmail3"
                        class="col-sm-3 col-4 col-form-label text-right"
                        >Spacing</label
                      >
                      <div class="col-sm-9 col-8">
                        <div class="btn-group" v-if="spacing.length > 0">
                          <button
                            type="button"
                            class="btn"
                            v-for="(space, index) in spacing"
                            :key="index + 'sp'"
                            :class="
                              orderForm.spacing === space.id
                                ? 'btn-dark'
                                : 'btn-outline-dark'
                            "
                            @click="activateSpacing(space)"
                          >
                            {{ space.desc }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-sm-3 text-right"
                    >Sources to be cited</label
                  >
                  <div class="col-md-4 col-8">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <button
                          class="btn btn-dark btn-icon"
                          type="button"
                          @click="decrementSources"
                        >
                          <i class="icon-minus3"></i>
                        </button>
                      </span>
                      <input
                        type="number"
                        v-model="orderForm.no_sources"
                        name="no_sources"
                        min="0"
                        class="form-control text-center"
                        placeholder
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-dark btn-icon"
                          type="button"
                          @click="orderForm.no_sources++"
                        >
                          <i class="icon-plus3"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-sm-3 text-right"
                    >Charts</label
                  >
                  <div class="col-md-4 col-6">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <button
                          class="btn btn-dark btn-icon"
                          type="button"
                          @click="updateCharts(0)"
                        >
                          <i class="icon-minus3"></i>
                        </button>
                      </span>
                      <input
                        @change="calculatePrice"
                        type="number"
                        v-model="orderForm.charts"
                        name="charts"
                        min="1"
                        class="form-control text-center"
                        placeholder
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-dark btn-icon"
                          type="button"
                          @click="updateCharts(1)"
                        >
                          <i class="icon-plus3"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-sm-3 text-right"
                    >PowerPoint slides</label
                  >
                  <div class="col-md-4 col-6">
                    <div class="input-group">
                      <span class="input-group-prepend">
                        <button
                          class="btn btn-dark btn-icon"
                          type="button"
                          @click="updateSlides(0)"
                        >
                          <i class="icon-minus3"></i>
                        </button>
                      </span>
                      <input
                        @change="calculatePrice"
                        type="number"
                        v-model="orderForm.slides"
                        name="slides"
                        min="1"
                        class="form-control text-center"
                        placeholder
                      />
                      <span class="input-group-append">
                        <button
                          class="btn btn-dark btn-icon"
                          type="button"
                          @click="updateSlides(1)"
                        >
                          <i class="icon-plus3"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>

                <div class="form-group row">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Writer preferences</label
                  >
                  <div class="col-sm-9">
                    <div class="btn-group row" v-if="writer_levels.length > 0">
                      <button
                        type="button"
                        class="btn col-4"
                        v-for="(pref, index) in writer_levels"
                        :key="index + 'wp'"
                        :class="
                          orderForm.writer_level === pref.id
                            ? 'btn-dark'
                            : 'btn-outline-dark'
                        "
                        @click="activateWriterPref(pref)"
                      >
                        <div>
                          <h6 class="my-0 text-left">
                            {{ pref.title }}
                            <br />
                            <span>{{ `(${pref.cost})` || "" }}</span>
                          </h6>
                          <p
                            class="my-0 text-left text-muted col-12"
                            style="white-space: normal; word-wrap: break-word"
                          >
                            {{ pref.desc }}
                          </p>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  class="form-group row"
                  v-if="isAuthenticated && Object.keys(authUser).length > 0"
                >
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Preferred Writer</label
                  >
                  <div class="col-sm-9">
                    <v-select
                      v-model="orderForm.writer_id"
                      @input="calculatePrice"
                      placeholder="Start typing to search"
                      :options="writers"
                      :reduce="(writer) => writer.id"
                    >
                      <template slot="option" slot-scope="option">
                        {{ option.first_name }}
                        <p class="is-rating-stars-1 d-flex mt-1 ml-1">
                          <span v-for="(i, index) in 5" :key="index + 'rating'">
                            <span
                              style="font-size: 16px !important"
                              class="text-warning icon mt-1"
                              :class="
                                option.rating.average_rating >= index + 1
                                  ? 'icon-star-full2'
                                  : 'icon-star-empty3'
                              "
                            ></span>
                          </span>
                          <span
                            style="
                              margin-left: 10px;
                              margin-top: 4px;
                              font-weight: bold;
                            "
                            >{{ option.rating.average_rating || "0" }}
                          </span>
                        </p>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        {{ option.first_name }}
                        <p class="is-rating-stars-1 d-flex mt-1 ml-1">
                          <span v-for="(i, index) in 5" :key="index + 'rating'">
                            <span
                              style="font-size: 16px !important"
                              class="text-warning icon mt-1"
                              :class="
                                option.rating.average_rating >= index + 1
                                  ? 'icon-star-full2'
                                  : 'icon-star-empty3'
                              "
                            ></span>
                          </span>
                          <span
                            style="
                              margin-left: 10px;
                              margin-top: 4px;
                              font-weight: bold;
                            "
                            >{{ option.rating.average_rating || "0" }}
                          </span>
                        </p>
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="form-group row" v-else>
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Writer Id</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="numeric"
                      v-model="orderForm.writer_id"
                      class="form-control"
                      id="inputEmail3"
                      @input="calculatePrice"
                    />
                    <div class="alert alert-success my-1">
                      Enter your favourite writer ID if you've had previous
                      writers.
                    </div>
                  </div>
                </div>

                <div
                  class="form-group row"
                  v-if="additional_services.length > 0"
                >
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Additional services</label
                  >
                  <div class="col-sm-9">
                    <div
                      v-for="(service, index) in additional_services"
                      :key="index"
                      class="p-2 is-well mb-1"
                    >
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="orderForm.additional_services"
                          @change="calculatePrice"
                          type="checkbox"
                          class="custom-control-input border-1"
                          :id="service.id"
                          :value="service.id"
                        />
                        <label class="custom-control-label" :for="service.id">
                          <strong class="my-0">
                            {{ service.title }}
                            <span class="ml-4">+ ${{ service.cost || 0 }}</span>
                          </strong>
                          <p class="my-1">{{ service.desc }}</p>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row form-group">
                  <label
                    for="inputEmail3"
                    class="col-sm-3 col-form-label text-right"
                    >Coupon Code</label
                  >
                  <div class="col-sm-9">
                    <input
                      type="text"
                      placeholder="Enter coupon code for discounts"
                      v-model="orderForm.coupon_code"
                      class="form-control"
                      id="inputEmail3"
                      @input="calculatePrice"
                    />

                    <div
                      v-if="message"
                      class="alert my-1"
                      :class="message.type ? 'alert-success' : 'alert-danger'"
                    >
                      <span v-html="message.message || ''"></span>
                    </div>

                    <div v-if="coupon" class="alert alert-success my-1">
                      <span v-html="coupon || ''"></span>
                    </div>
                  </div>
                </div>
              </form>

              <h1 class="mt-3">ACCOUNT</h1>
              <div v-if="isAuthenticated && Object.keys(authUser).length > 0">
                <div class="card is-card mb-4">
                  <div class="card-body py-1">
                    <h5 class="my-0">
                      You are logged in as {{ authUser.email || "" }}
                    </h5>
                  </div>
                </div>
              </div>

              <button
                type="button"
                @click="doUpdateOrder"
                class="btn btn-primary"
              >
                Update Order
              </button>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-4 col-md-3">
          <div class="card is-card">
            <div class="card-body">
              <h6 class="order-topic text-left">{{ orderForm.title }}</h6>

              <table
                class="borderless"
                style="min-width: 100%; text-align: left !important"
              >
                <tr>
                  <td class="order-short-info mb-0 pb-1">
                    {{ academic_level }}
                  </td>
                </tr>
                <tr>
                  <td class="order-short-info mb-0 pb-1">{{ subject_area }}</td>
                </tr>
                <tr>
                  <td class="order-short-info mb-0 pb-1">
                    {{ document_type }}
                  </td>
                </tr>
              </table>
              <hr class="mt-0" />
              <table
                class="borderless"
                style="min-width: 100%; text-align: left !important"
              >
                <tr v-for="(price, index) in getPrice" :key="index + 'test'">
                  <td>{{ price.title || "" }}</td>
                  <td class="text-right text-black font-weight-bold">
                    ${{ price.value || "" }}
                  </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <div
                      v-if="message"
                      class="alert my-1"
                      :class="message.type ? 'alert-success' : 'alert-danger'"
                    >
                      <span v-html="message.message || ''"></span>
                    </div>
                  </td>
                </tr>
              </table>
            </div>

            <div class="card-foooter px-3 pb-2">
              <div class="rounded mb-1 py-1 bg-danger-300" v-if="pages_message">
                {{ pages_message || "" }}
              </div>

              <button
                type="button"
                @click="doUpdateOrder"
                class="btn btn-block btn-primary"
              >
                Update Order
              </button>
            </div>

            <div class="text-center">
              <img :src="mcafee" width="100px" alt="mcafee secure" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import mcafee from "../../assets/global_assets/images/mcafee.png";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  name: "PlaceOrder",
  computed: {
    ...mapGetters("setups", [
      "academic_levels",
      "document_types",
      "language_styles",
      "number_of_pages",
      "spacing",
      "styles",
      "subject_areas",
      "urgency",
      "writer_levels",
      "additional_services",
      "defaults",
    ]),
    ...mapGetters("auth", ["isAuthenticated", "authUser"]),
    ...mapGetters("order", ["getPrice"]),
    ...mapState("order", ["message", "coupon", "order"]),
    ...mapState("writers", ["writers"]),
    ...mapState("setups", ["countries"]),
    no_of_words() {
      return (
        275 *
          this.orderForm.pages *
          this.spacing.find((x) => x.id == this.orderForm.spacing).factor || 1
      );
    },

    subject_area() {
      return (
        this.subject_areas.find((x) => x.id == this.orderForm.subject_area)
          .desc || ""
      );
    },
    document_type() {
      return (
        this.document_types.find((x) => x.id == this.orderForm.document_type)
          .desc || ""
      );
    },
    academic_level() {
      return (
        this.academic_levels.find((x) => x.id == this.orderForm.academic_level)
          .desc || ""
      );
    },

    pages_message() {
      let message = "";
      if (
        this.orderForm.pages < 1 &&
        this.orderForm.charts < 1 &&
        this.orderForm.slides < 1
      ) {
        message = "You need to order at least 1 page, 1 slide or 1 chart";
      } else message = "";
      return message;
    },
  },

  data() {
    return {
      mcafee: mcafee,
      customer_type: "new",
      is_loading: false,
      orderForm: new this.$Form({
        id: "",
        academic_level: "",
        subject_area: "",
        language_style: "",
        urgency: "",
        document_type: "",
        spacing: "",
        writer_level: "",
        style: 1,
        additional_services: [],
        pages: 1,
        no_sources: 0,
        charts: 0,
        slides: 0,
        title: "",
        description: "",
        state: "",
        speciality: "",
        files: [],
        writer_id: "",
        coupon_code: "",
      }),

      registerForm: new this.$Form({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        country: "",
        password: "",
      }),
      loginForm: new this.$Form({
        email: "",
        password: "",
      }),
    };
  },
  async mounted() {
    this.$store.state.loading = true;
    await this._getOrder(this.$route.params.id)
      .then(() => (this.$store.state.loading = false))
      .catch(() => {
        this.$router.push({ name: "404" });
      });
    await this._getSetups();
    await this.getWriters();
    await this._getCoupon();
  },
  methods: {
    ...mapActions("setups", ["getSetups", "getCountries"]),
    ...mapActions("auth", ["doLogin", "doRegister"]),
    ...mapActions("order", [
      "_calculatePrice",
      "_updateOrder",
      "_getCoupon",
      "_getOrder",
    ]),
    ...mapActions("writers", ["getWriters"]),
    _getSetups() {
      this.getSetups()
        .then((res) => {
          console.log("setups res", res);
          // set order form
          Object.assign(this.orderForm, { ...this.order });
          this.orderForm.pages = this.order.no_pages;
          this.orderForm.slides = this.order.no_slides;
          this.orderForm.charts = this.order.no_charts;
          this.orderForm.additional_services =
            this.order.additional_services.map((item) => {
              if (item[0] >= 0) {
                return item[0];
              }
            });

          this.orderForm.files = [];
          delete this.orderForm.buttons;
          delete this.orderForm.client;
          delete this.orderForm.additional_services_text;
          // this.orderForm.id = this.order.id;
          // this.orderForm.title = this.order.title;
          // this.orderForm.description = this.order.description;
          // this.orderForm.pages = this.order.pages;
          // this.orderForm.charts = this.order.charts;
          // this.orderForm.slides = this.order.slides;
          // this.orderForm.subject_area = this.order.subject_area;
          // this.orderForm.urgency = this.order.urgency;
          // this.orderForm.document_type = this.order.document_type;
          // this.orderForm.academic_level = this.order.academic_level;
          // this.orderForm.language_style = this.language_style;
          // this.orderForm.spacing = this.order.spacing;
          // this.orderForm.writer_level = this.order.writer_level;
          // this.orderForm.style = this.order.style;

          this.calculatePrice();
        })
        .catch((err) => {
          this.$router.push({ name: "503" });
          console.log(err);
        });
    },

    activateAcademicLevel(level) {
      this.orderForm.academic_level = level.id;
      this.calculatePrice();
    },
    activateLanguageStyle(style) {
      this.orderForm.language_style = style.id;
    },
    activateSpacing(space) {
      this.orderForm.spacing = space.id;
      this.calculatePrice();
    },
    activateWriterPref(pref) {
      this.orderForm.writer_level = pref.id;
      this.calculatePrice();
    },
    updatePages(option) {
      if (!option) {
        if (this.orderForm.pages > 0) {
          this.orderForm.pages--;
        }
      } else {
        this.orderForm.pages++;
        // if (this.orderForm.pages <= this.number_of_pages.max_pages) {
        // }
      }
      this.calculatePrice();
    },
    decrementSources() {
      if (this.orderForm.no_sources > 0) {
        this.orderForm.no_sources--;
      }
    },
    updateCharts(option) {
      if (!option) {
        if (this.orderForm.charts > 0) {
          this.orderForm.charts--;
        }
      } else {
        this.orderForm.charts++;
      }
      this.calculatePrice();
    },
    updateSlides(option) {
      if (!option) {
        if (this.orderForm.slides > 0) {
          this.orderForm.slides--;
        }
      } else {
        this.orderForm.slides++;
      }
      this.calculatePrice();
    },

    calculatePrice() {
      console.log("this.orderForm", this.orderForm);
      this.is_loading = true;
      this._calculatePrice(this.orderForm)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    uploadFiles(event) {
      this.orderForm.files = event.target.files;
    },

    doUpdateOrder() {
      this.is_loading = true;
      console.log("orderForm no_sources", this.orderForm.no_sources);
      this._updateOrder(this.orderForm)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            this.$router.push(
              `/${process.env.VUE_APP_INSTALL_DIR}/home/order/${this.order.id}`
            );
            this.loading = false;
          } else {
            this.$notify({
              title: "Error",
              text: res.data
                ? res.data.Message
                : "Error while placing order, please try again",
              style: "danger",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>

<style scoped lang="scss">
.is-card {
  position: sticky;
  top: 20px;
}

@media screen and (max-width: 768px) {
  label {
    text-align: left !important;
  }
}

.order-topic.text-left {
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  // min-height: 4.5em !important;
  max-height: 4.5em !important;
}
.btn-group {
  overflow-x: scroll;
  width: 100%;
}
</style>